<template>

    <div class="d-flex justify-end my-2">
        <div>
            <VvtFilter
                :selected="selectedFilterId"
                @select="$emit('set-current-filter', $event)"
                prepend-title
            />
        </div>
    </div>
</template>

<script>
const VvtFilter = () => import(/* webpackChunkName: "VvtFilter" */ '@/components/vvt/Filter.vue');

export default {
    components: {
        VvtFilter,
    },
    props: {
        selectedFilterId: {
            type: [Number, String],
            default: 'all'
        }
    }
}
</script>