<template>
    <v-card class="v-card--material mx-auto" color="secondary">
        <div class="d-flex--v2 grow flex-wrap">
            <v-sheet
                color="green darken-2"
                max-height="90"
                width="auto"
                class="text-start v-card--material__heading mb-n6 elevation-6 pa-7">
                <v-icon large color="white">
                    mdi-chart-box
                </v-icon>
            </v-sheet>
        </div>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6" lg="4" xl="3" class="order-1">
                    <v-card outlined subtitle height="100%">
                        <v-card-text class="pa-0">
                            <component :is="'DonutChart'" :title="$t('vvtList.kpi.statusChart.title')" :key="'statusChart_' + statusChartKeyIndex" :chart-data="kpiStatusChartData" />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-if="$hasRole('ROLE_TENANT_READER')" cols="12" md="6" lg="8" xl="6" class="order-2">
                    <v-card outlined subtitle height="100%">
                        <v-card-text class="pa-0">
                            <component :is="'BarChart'" stacked :data="kpiRiskChartData" :title="$t('vvtList.kpi.riskChart.title')" chartKey="riskChart" />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-if="$hasRole('ROLE_TENANT_READER')" cols="12" :md="getCompanyGroupSettings('useVendor') ? 6 : 12" xl="3" class="order-3">
                    <v-card outlined subtitle height="100%">
                        <v-card-text class="pa-0">
                            <component :is="'BarChart'" :data="kpiDpiaChartData" :title="$t('vvtList.kpi.dpiaChart.title')" chartKey="dpiaChart" />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" class="order-4 order-md-5 order-xl-4" :lg="12" :xl="!$hasRole('ROLE_TENANT_READER') || getCompanyGroupSettings('useVendor') ? 9 : 12">
                    <v-card outlined subtitle height="100%">
                        <v-card-text class="pa-0">
                            <component v-if="showMap" :is="'MapChart'" :data="kpiDataFlowData" :title="$t('vvtList.kpi.dataflowChart.title')" chartKey="dataflowChart" />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-if="getCompanyGroupSettings('useVendor') && $hasRole('ROLE_TENANT_READER')" class="order-5 order-md-4 order-xl-5" cols="12" md="6" lg="6" xl="3">
                    <v-card outlined subtitle height="100%">
                        <v-card-text class="pa-0">
                            <component :is="'BarChart'" :data="kpiDpaChartData" :title="$t('vvtList.kpi.dpaChart.title')" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <DashboardFilter
                :selected-filter-id="selectedFilterId"
                @set-current-filter="$emit('set-current-filter', $event)"
            />
            <template v-if="$wait.is('fetch companies')">
                <v-skeleton-loader
                    loading
                    :type="'table-thead, table-tbody, table-tfoot'"
                    tile
                    class="px-0"
                ></v-skeleton-loader>
            </template>
            <template v-else>
                <v-data-table
                    :headers="tableHeaders"
                    :items="filteredCompanyItems"
                    :sort-by="initialTableSortby"
                    @update:sort-by="setSortBy($event)"
                    :sort-desc="initialTableSortDesc"
                    @update:sort-desc="setSortDesc($event)"
                    :items-per-page="10"
                    calculate-widths
                    class="data-table--row-cursor-pointer"
                    @click:row="expandRow"
                    :expanded.sync="expanded"
                    show-expand
                    single-expand
                    :footer-props="{
                        itemsPerPageOptions: [5, 10, 25, 50, -1],
                        itemsPerPageText: $t('perPage'),
                        itemsPerPageAllText: $t('all'),
                        pageText: '{0}-{1} ' + $t('from') + ' {2}'
                    }"
                >
                    <template v-slot:[`body.prepend`]="{ headers }">
                        <tr @click="selectCompany({id: 'all'})" class="primary--text">
                            <td :colspan="headers.length - ($vuetify.breakpoint.lgAndUp ? 7 : 1)">
                                {{ $t('vvtList.kpi.listAllRecords') }}
                            </td>
                            <td>
                                {{ filteredCompanyItems.reduce((total, company) => total + company.processingActivityCount, 0) }}
                            </td>
                            <td v-if="$vuetify.breakpoint.lgAndUp">
                                {{ filteredCompanyItems.reduce((total, company) => total + company.processingActivityStatusDraftCount, 0) }}
                            </td>
                            <td v-if="$vuetify.breakpoint.lgAndUp">
                                {{ filteredCompanyItems.reduce((total, company) => total + company.processingActivityStatusReviewCount, 0) }}
                            </td>
                            <td v-if="$vuetify.breakpoint.lgAndUp">
                                {{ filteredCompanyItems.reduce((total, company) => total + company.processingActivityStatusApprovalCount, 0) }}
                            </td>
                            <td v-if="$vuetify.breakpoint.lgAndUp">
                                {{ filteredCompanyItems.reduce((total, company) => total + company.processingActivityStatusReleaseCount, 0) }}
                            </td>
                            <td v-if="$vuetify.breakpoint.lgAndUp">
                                {{ filteredCompanyItems.reduce((total, company) => total + company.processingActivityStatusFinalisedCount, 0) }}
                            </td>
                            <td v-if="$vuetify.breakpoint.lgAndUp">
                                {{ filteredCompanyItems.reduce((total, company) => total + company.processingActivityStatusArchivedCount, 0) }}
                            </td>
                        </tr>
                    </template>
                    <template v-slot:[`item.countryCode`]="{ item }">
                        <v-avatar v-if="item.countryCode" size="24">
                        <img
                            :src="`${$publicPath}images/countries/${item.countryCode.toLowerCase()}.svg`"
                            :alt="item.countryName"
                        >
                        </v-avatar>
                    </template>
                    <template v-slot:[`item.data-table-expand`]="{isExpanded}">
                        <v-btn icon>
                            <v-icon>
                                {{ isExpanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                            </v-icon>
                        </v-btn>
                    </template>  
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pb-3">
                            <v-row>
                                <v-col cols="12" md="6" lg="6" xl="4">
                                    <v-card flat subtitle color="transparent" height="100%">
                                        <v-card-text class="pa-0">
                                            <pre v-if="false">{{ item }}</pre>
                                            <component :is="'DonutChart'" :height="320" :title="$t('vvtList.kpi.statusChart.title')" legend-position="bottom" container-alignment="center" :key="'statusChart_company_' + item.id + '_' + statusChartKeyIndex" :chart-data="[
                                                { 
                                                    'title': $t('globalFilterAlias.statusDraft'), 
                                                    'value': item.processingActivityStatusDraftCount,
                                                    'color': getColor($getStatusColorClass('draft', 'hex')),
                                                    'extraData': {
                                                        'company': item.id,
                                                        'filterAlias': 'statusDraft'
                                                    }
                                                },
                                                { 
                                                    'title': $t('globalFilterAlias.statusReview'), 
                                                    'value': item.processingActivityStatusReviewCount,
                                                    'color': getColor($getStatusColorClass('review', 'hex')),
                                                    'extraData': {
                                                        'company': item.id,
                                                        'filterAlias': 'statusReview'
                                                    }
                                                },
                                                { 
                                                    'title': $t('globalFilterAlias.statusApproval'), 
                                                    'value': item.processingActivityStatusApprovalCount,
                                                    'color': getColor($getStatusColorClass('approval', 'hex')),
                                                    'extraData': {
                                                        'company': item.id,
                                                        'filterAlias': 'statusApproval'
                                                    }
                                                },
                                                { 
                                                    'title': $t('globalFilterAlias.statusRelease'), 
                                                    'value': item.processingActivityStatusReleaseCount,
                                                    'color': getColor($getStatusColorClass('release', 'hex')),
                                                    'extraData': {
                                                        'company': item.id,
                                                        'filterAlias': 'statusRelease'
                                                    }
                                                },
                                                { 
                                                    'title': $t('globalFilterAlias.statusFinale'), 
                                                    'value': item.processingActivityStatusFinalisedCount,
                                                    'color': getColor($getStatusColorClass('finalised', 'hex')),
                                                    'extraData': {
                                                        'company': item.id,
                                                        'filterAlias': 'statusFinale'
                                                    }
                                                },
                                                { 
                                                    'title': $t('globalFilterAlias.statusArchived'), 
                                                    'value': item.processingActivityStatusArchivedCount,
                                                    'color': getColor($getStatusColorClass('archived', 'hex')),
                                                    'extraData': {
                                                        'company': item.id,
                                                        'filterAlias': 'statusArchived'
                                                    }
                                                },
                                            ]" />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                </v-data-table>
            </template>
        </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { color as am4CoreColor } from '@amcharts/amcharts4/core';
import DashboardFilter from '@/components/dashboard/DashboardFilter/';
const DonutChart = () => import(/* webpackChunkName: "DonutChart" */ '@/components/Chart/Donut.vue');
const BarChart = () => import(/* webpackChunkName: "BarChart" */ '@/components/Chart/Bar.vue');
const MapChart = () => import(/* webpackChunkName: "MapChart" */ '@/components/Chart/Map.vue');

const LEA_VVT_DASHBOARD_TABLE_SORTBY = 'LEA_VVT_DASHBOARD_TABLE_SORTBY';
const LEA_VVT_DASHBOARD_TABLE_SORTDESC = 'LEA_VVT_DASHBOARD_TABLE_SORTDESC';

export default {
    name: 'DashboardVvtKpi',
    props: {
        selectedFilterId: {
            type: [Number, String],
            default: 'all'
        }
    },
    components: {  
        DashboardFilter,
        DonutChart,
        BarChart,
        MapChart
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    data () {
        return {
            expanded: [],
            statusChartKeyIndex: 1,
            allTableHeaders: [
                { text: this.$t('vvtList.recordPreview.companyName'), align: 'start', value: 'title' },
                { sortable: false, value: 'countryCode' },
                { text: this.$t('vvtList.recordPreview.country'), value: 'countryName' },
                { text: this.$t('vvtList.recordPreview.city'), value: 'city' },
                { text: this.$tc('processingActiviy', 2), value: 'processingActivityCount' },
                { text: this.$t('vvtList.recordPreview.recordCountDraft'), value: 'processingActivityStatusDraftCount', optional: true },
                { text: this.$t('vvtList.recordPreview.recordCountReview'), value: 'processingActivityStatusReviewCount', optional: true  },
                { text: this.$t('vvtList.recordPreview.recordCountApproval'), value: 'processingActivityStatusApprovalCount', optional: true  },
                { text: this.$t('vvtList.recordPreview.recordCountRelease'), value: 'processingActivityStatusReleaseCount', optional: true  },
                { text: this.$t('vvtList.recordPreview.recordCountActive'), value: 'processingActivityStatusFinalisedCount', optional: true  },
                { text: this.$t('vvtList.recordPreview.recordCountArchived'), value: 'processingActivityStatusArchivedCount', optional: true  },
            ],
            showMap: false
        } 
    },
    computed: {
        ...mapGetters({
            companyCount: 'dashboard/companyCount',
            recordCount: 'dashboard/vvtRecordCount',
            recordDraftCount: 'dashboard/vvtRecordDraftCount',
            recordReviewCount: 'dashboard/vvtRecordReviewCount',
            recordApprovalCount: 'dashboard/vvtRecordApprovalCount',
            recordReleaseCount: 'dashboard/vvtRecordReleaseCount',
            recordFinalisedCount: 'dashboard/vvtRecordFinalisedCount',
            recordArchivedCount: 'dashboard/vvtRecordArchivedCount',
            getFilteredCompanies: 'companies/getFilteredCompanies',
            recordsLowOrMediumRiskCount: 'dashboard/vvtRecordLowOrMediumRiskCount',
            recordsHighOrVeryHighRiskCount: 'dashboard/vvtRecordHighOrVeryHighRiskCount',
            recordsDpiaMissingCount: 'dashboard/vvtRecordDpiaMissingCount',
            recordsDpiaCompletedCount: 'dashboard/vvtRecordDpiaCompletedCount',
            kpiDataFlowData: 'dashboard/vvtRecordDataFlow',
            vendorDpaCompletedCount: 'dashboard/vendorDpaCompletedCount',
            vendorDpaMissingCount: 'dashboard/vendorDpaMissingCount',
            vendorDpaThirdCountryWithoutSafeguardCount: 'dashboard/vendorDpaThirdCountryWithoutSafeguardCount',
            getCompanyGroupSettings: 'companyGroupSettings/get',
        }),
        recordsInWorkCount() {
            return (this.recordDraftCount + this.recordReviewCount + this.recordApprovalCount + this.recordReleaseCount);
        },
        kpiStatusChartData() {
            return [
                { 
                    'title': this.$t('vvtList.kpi.statusChart.finale'), 
                    'value': this.recordFinalisedCount,
                    'color': am4CoreColor('#388e3c'),
                    'extraData': {
                        'filterAlias': 'statusFinale'
                    }
                },
                { 
                    'title': this.$t('vvtList.kpi.statusChart.work'), 
                    'value': this.recordsInWorkCount,
                    'color': am4CoreColor(this.$getStatusColorClass('inWork', 'hex')),
                    'extraData': {
                        'filterAlias': 'statusInWork'
                    }
                },
                { 
                    'title': this.$t('vvtList.kpi.statusChart.archive'), 
                    'value': this.recordArchivedCount,
                    'color': am4CoreColor(this.$getStatusColorClass('archived', 'hex')),
                    'extraData': {
                        'filterAlias': 'statusArchived'
                    }
                },
            ];
        },
        kpiRiskChartData () {
            return this.$sortNumerical([
                {
                    category: 'risk',
                    //key: 'low',
                    title: this.$t('vvtList.kpi.riskChart.lowRisk'),
                    value: this.recordsLowOrMediumRiskCount,
                    color: am4CoreColor('#388e3c'),
                    filterAlias: 'lowRisk',
                    extraData: {
                        key: 'low',
                        filterAlias: 'lowRisk'
                    }
                },
                {
                    category: 'risk',
                    //key: 'high',
                    title: this.$t('vvtList.kpi.riskChart.highRisk'),
                    value: this.recordsHighOrVeryHighRiskCount,
                    color: am4CoreColor('#db651b'),
                    filterAlias: 'highRisk',
                    extraData: {
                        key: 'high',
                        filterAlias: 'highRisk'
                    }
                }
            ], 'value');
        },
        kpiDpiaChartData () {
            return this.$sortNumerical([
                    {
                        key: 'completed',
                        title: this.$t('vvtList.kpi.statusChart.finale'),
                        value: this.recordsDpiaCompletedCount,
                        color: am4CoreColor('#388e3c'),
                        filterAlias: 'dpiaCompleted',
                        category: 'default',
                        extraData: {
                            key: 'completed',
                            filterAlias: 'dpiaCompleted'
                        }
                    },
                    {
                        key: 'missing',
                        title: this.$t('vvtList.kpi.statusChart.work'),
                        value: this.recordsDpiaMissingCount,
                        color: am4CoreColor(this.$getStatusColorClass('inwork', 'hex')),
                        filterAlias: 'dpiaMissing',
                        category: 'default',
                        extraData: {
                            key: 'missing',
                            filterAlias: 'dpiaMissing'
                        }
                    }
                ], 'value');
        },
        kpiDpaChartData () {
            return this.$sortNumerical([
                        {
                            key: 'thirdCountryWithoutSafeguards',
                            title: this.$t('vvtList.kpi.dpaChart.thirdCountryWithoutSafeguards'),
                            value: this.vendorDpaThirdCountryWithoutSafeguardCount,
                            color: am4CoreColor('#db651b'),
                            filterAlias: 'dpaThirdCountryWithoutSafeguards',
                            extraData: {
                                key: 'thirdCountryWithoutSafeguards',
                                filterAlias: 'dpaThirdCountryWithoutSafeguards',
                                clickedRoute: {
                                    name: 'SettingsVendor'
                                }
                            }
                        },
                        {
                            key: 'completed',
                            title: this.$t('vvtList.kpi.statusChart.finale'),
                            value: this.vendorDpaCompletedCount,
                            color: am4CoreColor('#388e3c'),
                            filterAlias: 'dpaCompleted',
                            extraData: {
                                key: 'completed',
                                filterAlias: 'dpaCompleted',
                                clickedRoute: {
                                    name: 'SettingsVendor'
                                }
                            }
                        },
                        {
                            key: 'missing',
                            title: this.$t('vvtList.kpi.statusChart.work'),
                            value: this.vendorDpaMissingCount,
                            color: am4CoreColor(this.$getStatusColorClass('inwork', 'hex')),
                            filterAlias: 'dpaMissing',
                            extraData: {
                                key: 'missing',
                                filterAlias: 'dpaMissing',
                                clickedRoute: {
                                    name: 'SettingsVendor'
                                }
                            }
                        }
                    ], 'value');
        },
        tableHeaders () {
            if (this.$vuetify.breakpoint.mdAndDown) {
                return this.allTableHeaders.filter(x => !x.optional)
            }
            return this.allTableHeaders;
        },
        filteredCompanyItems () {
            return this.getFilteredCompanies(this.selectedFilterId);
        },
        initialTableSortby () {
            let storageSortby = localStorage.getItem(LEA_VVT_DASHBOARD_TABLE_SORTBY);
            if (storageSortby === null || storageSortby === undefined) {
                return 'processingActivityCount';
            }
            return storageSortby;
        },
        initialTableSortDesc () {
            let storageSortDesc = localStorage.getItem(LEA_VVT_DASHBOARD_TABLE_SORTDESC);
            if (storageSortDesc === null || storageSortDesc === undefined || storageSortDesc === 'true' || storageSortDesc === true) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
      ...mapActions({
            setEventStore: 'eventStore/set',
            fetchFilter: 'filter/fetch',
            fetchCompaniesByFilter: 'companies/fetchByFilter',
            fetchKpi: 'dashboard/fetchKpi'
      }),
        getColor(val) {
            return am4CoreColor(val);
        },
        expandRow (val, props) {
            props.expand(!props.isExpanded);
        },
      setSortBy (val) {
        if (val !== undefined) {
            localStorage.setItem(LEA_VVT_DASHBOARD_TABLE_SORTBY, val);
        } else {
            localStorage.removeItem(LEA_VVT_DASHBOARD_TABLE_SORTBY);
        }
      },
      setSortDesc (val) {
        if (val !== undefined) {
            localStorage.setItem(LEA_VVT_DASHBOARD_TABLE_SORTDESC, val);
        } else {
            localStorage.removeItem(LEA_VVT_DASHBOARD_TABLE_SORTDESC);
        }
      },
      selectCompany (objCompany) {
        let objRoute = {
            name: 'VvtList',
            params: {
                filterId: this.selectedFilterId
            }
        };
        if (objCompany.id !== 'all') {
            objRoute.params.group = 'company';
            objRoute.params.groupId = objCompany.id;
        }
        this.$router.push(objRoute);
      },
      fetchCurrentFilterCompanies (filter, force) {
        // this.selectedFilterId = filter ? filter : 'all';
        this.$wait.start('fetch companies');
        this.fetchCompaniesByFilter({ filterId: this.selectedFilterId, force: force }).then(() => {
          this.$wait.end('fetch companies');
        });
      },
    },
    beforeCreate () {
        this.$wait.start('fetch kpi');
        this.$wait.start('fetch companies');
    },
    mounted () {
        this.fetchKpi({force: true}).then(() => {
            this.$wait.end('fetch kpi');
            this.$nextTick().then(() => {
                this.showMap = true;
            });
        });
        
        this.fetchCurrentFilterCompanies(this.selectedFilterId, true);
        this.setEventStore({name: 'selectedCompanyId', value: null});
    },
    watch: {
        $route(to, from) {
            if (this.$route.name === 'Home' && parseInt(to.params.filter) !== parseInt(from.params.filter)) {
                this.fetchCurrentFilterCompanies(parseInt(to.params.filter), true);
            }
        },
        recordFinalisedCount(val, oldVal) {
            if(oldVal !== val) {
                this.statusChartKeyIndex++;
            }
        },
        recordsInWorkCount(val, oldVal) {
            if(oldVal !== val) {
                this.statusChartKeyIndex++;
            }
        },
        recordArchivedCount(val, oldVal) {
            if(oldVal !== val) {
                this.statusChartKeyIndex++;
            }
        }
    },
    deactivated () {
        this.showMap = false;
    },
    activated () {
        this.$wait.start('fetch kpi');
        this.fetchKpi({force: true}).then(() => {
            this.$wait.end('fetch kpi');        
            this.$nextTick().then(() => {
                this.showMap = true;
            });
        });
        this.fetchCurrentFilterCompanies(this.selectedFilterId, true);
        this.setEventStore({name: 'selectedCompanyId', value: null});
    },
}
</script>
